.tableCustomize {
    :global(
            .ant-table-tbody
                > tr
                > td
                > .ant-table-wrapper:only-child
                .ant-table
        ) {
        margin: 0px !important;
    }
    :global(.ant-table) {
        margin: 0px !important;
    }
    :global(tr.ant-table-expanded-row > td) {
        padding: 0px !important;
    }
    :global(.ant-table-thead > tr > th) {
        padding: 10px 16px !important;
    }
    :global(.ant-table-tbody > tr > td) {
        // padding: 10px 16px !important;
    }
    :global(
            tr.ant-table-expanded-row > td,
            tr.ant-table-expanded-row:hover > td
        ) {
        margin: 0px !important;
    }
}

