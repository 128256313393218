.web-top-wrapper {
    height: 620px;
}
.app-menu-item {
    width: 100%;
    max-width: 140px;
    height: 140px;
    background-color: #d5e5ff;
    border: 1px solid #e8e8e8;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
    color: #585858;
    &:hover {
        text-decoration: none;
    }
    img {
        width: 40px;
    }
    h4 {
        color: #585858;
    }
}
