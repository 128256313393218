.step-progress-container {
    height: 222px;
    /* border-top: 0.1rem solid #8d95a0; */
    position: relative;
    width: calc(100% - 60px);
    margin-left: 20px;
    margin-bottom: 160px;
    margin-top: 10px;
}

.step-progress-container-top {
    width: calc(100% + 20px);
    /* height: 166px; */
    height: 185px;
    border-right: 0.1rem solid #8d95a0;
    border-bottom: 0.1rem solid #8d95a0;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    top: 0;
    left: -20px;
}

.step-progress-container-bottom {
    width: calc(100% + 20px);
    height: 56px;
    border-left: 0.1rem solid #8d95a0;
    border-bottom: 0.1rem solid #8d95a0;
    position: absolute;
    top: 185px;
    left: -20px;
}

@media only screen and (max-width: 576px) {
    .step-progress-container,
    .step-progress-container-top,
    .step-progress-container-bottom {
        max-width: 600px;
    }
}

.step-item {
    width: calc(100% / 6);
    height: 100%;
    /* background-color: red; */
    position: relative;
}

.step-circle {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    top: -20px;
    left: -20px;
    align-items: center;
    justify-content: center;
    /* z-index: 10; */
    cursor: pointer;
}

.step-text {
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.step-circle-bt {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    bottom: -20px;
    left: -20px;
    align-items: center;
    justify-content: center;
    /* z-index: 10; */
    cursor: pointer;
}

.step-text-bt {
    position: absolute;
    /* bottom: -50px; */
    left: 0;
    transform: translate(-50%, 80px);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.step-circle-bt-13 {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    bottom: -20px;
    right: -20px;
    align-items: center;
    justify-content: center;
    /* z-index: 10; */
    cursor: pointer;
}

.step-text-bt-13 {
    position: absolute;
    /* bottom: -50px; */
    right: 0;
    transform: translate(50%, 80px);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.sub-step-item {
    width: calc(100% / 6);
    height: 100px;
    position: absolute;
    top: 0px;
    right: 7.7%;
    border-left: 0.1rem solid #8d95a0;
    border-bottom: 0.1rem solid #8d95a0;
    border-right: 0.1rem solid #8d95a0;
}

.sub-step-circle {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    bottom: -20px !important;
    left: calc(50% - 20px);
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sub-step-text {
    position: absolute;
    /* bottom: -60%; */
    left: 50%;
    transform: translate(-50%, 125px);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.sub-step-item8 {
    width: calc(100% / 6);
    height: 105px;
    position: absolute;
    bottom: -105px;
    left: calc(8.333% + 33px);
    border-left: 0.1rem solid #8d95a0;
    border-bottom: 0.1rem solid #8d95a0;
    border-right: 0.1rem solid #8d95a0;
}

.sub-step-circle8 {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    bottom: -20px !important;
    left: calc(50% - 20px);
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sub-step-item10 {
    width: calc(100% / 6);
    height: 105px;
    position: absolute;
    bottom: -105px;
    left: calc(41.6667% + 19px);
    border-left: 0.1rem solid #8d95a0;
    border-bottom: 0.1rem solid #8d95a0;
    border-right: 0.1rem solid #8d95a0;
}

.sub-step-circle10 {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    bottom: -20px !important;
    left: calc(50% - 20px);
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.completeStep {
    background-color: #215493;
    color: #ffffff;
}

.unCompleteStep {
    background-color: #e3e5e8;
    color: #215493;
}

.completeText {
    color: #1c1e21;
}

.unCompleteText {
    color: #58606a;
}
.w-full {
    width: 100%;
}

.d-f {
    display: flex;
}

.fc {
    flex-direction: column;
}

.fr {
    flex-direction: row;
}

.modal-step1 {
    width: 380px;
    height: 258px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px 24px 24px 24px;
}

.modal-step1-title {
    /* height: 50px; */
    justify-content: space-between;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1c1e21;
}

.modal-sugoroku {
    max-height: 95vh;
}

.modal-sugoroku .MuiDialog-container .MuiPaper-root {
    overflow-y: visible;
    max-width: 1000px;
    overflow: auto;
    @media only screen and (max-width: 576px) {
        max-width: 100%;
        overflow-x: auto;
    }
}

.modal-step1-content {
    border: 1px solid #cdd1d5;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    margin-top: 24px;
}

.modal-step1-content-title {
    width: 30%;
    background-color: #c7dcf5;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.modal-step1-title-dt {
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    height: 25%;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
}
.modal-step1-content2 {
    width: 70%;
    height: 100%;
}
.model-step1-inf {
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    height: 25%;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
}

.height33 {
    height: 33.33333% !important;
}

/* .link-pr {
    position: absolute;
    right: -20px;
    bottom: -120px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #406fe5;
} */

.dialog-step {
    width: 380px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px 24px 24px 24px;
}
.dialog-step-6 {
    width: 420px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px 24px 24px 24px;
}
.dialog-step-9 {
    width: 600px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px 24px 24px 24px;
    overflow-y: auto;
}

.step-title-head {
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 8px;
}
.three-box-flex {
    display: flex;
    align-items: center !important;
}

.dialog-step-row {
    width: 100%;
    display: flex;
}

.step-title {
    justify-content: center;
    align-items: center;
    height: 40px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    min-width: 30%;
    background-color: #c7dcf5;
    border-bottom: 1px solid #f5f5f5;
}
.step-title-min {
    justify-content: center;
    align-items: center;
    height: 40px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1c1e21;
    min-width: 10%;
    background-color: #c7dcf5;
    border-bottom: 1px solid #f5f5f5;
}

.radio-group {
    width: 160px;
    margin-left: 10px;
    border-radius: 8px;
    height: 36px;
}

.action-center {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.btn-create {
    background-color: #215493;
    color: white;
    padding: 8px 24px;
    border-radius: 4px;
}

.step-paper {
    max-width: 100% !important;
}

.margin-bottom-24 {
    margin-bottom: 220px;
}

.radio-group {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.datepicker-wrap-sugoroku .input-date {
    border: none !important;
    max-width: 210px;
    position: relative;
}

.datepicker-wrap-sugoroku .input-date input {
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
}

.input-date {
    max-width: 180px;
}

.input-style input {
    height: 36px;
    padding: 0px;
    padding-left: 5px;
    width: 210px;
    border-radius: 8px;
}

.small-input-style input {
    height: 36px;
    padding: 0px;
    padding-left: 5px;
    border-radius: 8px;
}

.input-select-style {
    max-width: 210px;
}
