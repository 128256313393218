.inputCommon {
    :global(.ant-input) {
        border-radius: 8px;
        height: 27px;
    }
    :global(.ant-form-item-explain-error) {
        overflow: hidden !important;
        display: -webkit-box !important;
        -webkit-box-orient: vertical !important;
        -webkit-line-clamp: 1 !important;
        height: 20px !important;
    }
}

.inputCommonRight {
    :global(.ant-input) {
        text-align: right;
    }
}

.inputCommonCenter {
    :global(.ant-input) {
        text-align: center;
    }
}

.tableInvoiceCustom {
    :global(.ant-table-tbody > tr > td:first-child) {
        vertical-align: middle !important;
    }
    :global(.ant-table-tbody > tr > td:last-child) {
        vertical-align: middle !important;
    }

    :global(.ant-table-tbody > tr > td) {
        padding: 12px;
        vertical-align: top;
    }

    :global(.ant-table-thead > tr > th) {
        background-color: #377acd;
        color: #ffffff;
        padding: 12px;
    }
}
