.tableCustom {
    :global(.ant-table-thead > tr > th) {
        background-color: #377acd;
        color: #ffffff;
        padding: 12px;
    }
    :global(.ant-table-tbody > tr > td:last-child) {
        vertical-align: top !important;
    }
    :global(.ant-table-tbody > tr > td) {
        padding: 10px !important;
        vertical-align: middle;
    }

    :global(.ant-input) {
        border-radius: 8px;
    }
    :global(.ant-select-selector) {
        border-radius: 8px !important;
        background: #f3f8fd !important;
        color: #1890ff !important;
        border: none !important;
        box-shadow: none !important;
        height: 32px !important;
    }
    :global(.ant-picker) {
        border-radius: 8px !important;
    }
    :global(.ant-form-item-explain-error) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
}
