.tableCustom {
    :global(.ant-table-thead > tr > th) {
        background-color: #377acd;
        color: #ffffff;
        padding: 12px;
    }
    :global(.ant-table-tbody > tr > td) {
        padding: 10px !important;
    }
}

.selectCommon {
    :global(.ant-select-selection-item) {
        display: flex;
        align-items: center;
    }
}