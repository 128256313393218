.table-company {
    height: calc(100% - 130px);
}

.search-client-company-button {
    width: 100%;
    justify-content: flex-start;
    height: 70px;
}

.search-client-company-row-button {
    height: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 1px;
}
.table-company {
    .ant-table-thead > tr > th {
        text-align: left !important;
        background-color: #C7DCF5;
        
    }
}
.modal-review-company {
    width: 462px;
    min-height: 366px;
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
}
