.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    width: 30px !important;
    height: 30px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye svg {
    width: 30px !important;
    height: 30px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    width: 30px !important;
    height: 30px !important;

    svg {
        width: 30px !important;
        height: 30px !important;
    }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
    left: 45% !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0 !important;
}

@media only screen and (max-width: 450px) {
    // .ant-picker-panel-container {
    //  max-width: 330px !important;
    // overflow: scroll !important;
    // }
    .ant-picker-panels {
        flex-direction: column !important;
        align-items: center !important;
    }
}
