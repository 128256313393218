.height36 {
    height: 36px !important;
}
.width-full {
    width: 100% !important;
    margin-right: 28px;
    margin-left: 0px !important;
    margin-top: 22px;
}
.search-icon {
    font-family: 'FontAwesome';
}

#input-search::placeholder {
    color: #000;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #000;
}

.image {
    position: relative;
}

.ant-select-selector {
    height: 40px !important;
}

.tag {
    min-width: 42px;
    height: 21px;
    position: absolute;
    top: 8px;
    left: 0;
    font-size: 14px;
    color: white;
    text-align: center;
}

.dismantling {
    background: #ff5045;
}

.industrial-waste {
    background: #754d4d;
}

.project-list-search-select-style {
    width: 100% !important;
    height: 36px !important;
    padding: 0px !important;
    /* margin-right: 16px; */
}

.project-list-search-select-style .MuiOutlinedInput-root {
    height: 36px;
    padding: 0px !important;
}

.project-list-search-select-style .autocomplete-custom-textfield input {
    padding-left: 10px !important;
}

.project-list-search-select-style .MuiAutocomplete-root {
    height: 36px;
    padding: 0px !important;
}

.project-list-search-select-style fieldset {
    height: 42px !important;
    border-radius: 8px;
}
